@import "~simple-datatables/src/style";

.partners {
}

.partners-datatables {
  .dataTable-search {
    width: 100%;
  }

  .dataTable-input {
    display: block;
    width: 100%;
    padding: 8px 10px;

    font-size: 16px;
    line-height: 24px;
    color: #151716;
    font-weight: 400;

    border-radius: 2px;

    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;

    transition: border-color 250ms ease-in-out;

    &::placeholder {
      opacity: 0.7;
    }

    &:hover {
      border-color: #747474;

      transition: border-color 250ms ease-in-out;
    }

    &:focus {
      outline: none;
      border-color: #ff7028;

      transition: border-color 250ms ease-in-out;
    }
  }

  .dataTable-top {
    padding: 8px 0;
  }
}
